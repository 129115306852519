import React from 'react'

export default function Three() {
  return (
    <div className="m-0 d-flex justify-content-around position-relative pb-5" style={{zIndex:100}} id="roadmap">
      <div className="d-flex flex-column justify-content-center ps-5 pe-5">
        <span className="align-self-center fs-1 ps-5">ROADMAP</span>
        <span className="fs-5 pt-5 text-wrap text-break textsquare blue-text">
Dawdlerz is a community-driven NFT project that aims to create a uniquely fun universe with different initiatives and offer great opportunities to its owners. We want to step up and make a difference in Web3-targeted applications. While our primary goal is to develop "Dawdlerz" brand-focused products for the gaming and toy industries with experts in their fields, we will always work on growing our community and making it more fun. We will never stop innovating. <br /><br /> We want to take steps that will always be in the interest of the Dawdlerz NFT collection owners. We will pursue a path towards increasing brand value and profit potential for everyone involved in this community. We have numerous exciting projects in the pipeline. But as a first step, we are working to introduce Dawdlerz to the whole world. And we are very excited about where we imagine all this will lead to:
This is Dawdler City.
          <div className="text-end">
          Welcome.
          </div>
          </span>

      </div>
    </div>
  )
}
