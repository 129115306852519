import React from 'react'
const status = process.env.REACT_APP_MINT

const ConnectButton = ({ metamask, provider, setIsOpen, isOpen }) => {
  if(status === "NO"){
    return (<button className="btn btn-lg bg-info mint-button-padding rounded rounded-pill fs-2 text-white">SOON</button>)
  }

  if(status === "YES")
  return(
    provider.address ? 
    <button className="btn btn-lg bg-info mint-button-padding rounded rounded-pill fs-2 text-white" 
    onClick={() => setIsOpen(!isOpen)}>MINT</button>
    :
    <button className="btn btn-lg bg-warning mint-button-padding rounded rounded-pill fs-2 text-white" onClick={metamask}>Connect</button>
  )

}


export default function One({metamask, provider, setIsOpen, isOpen }) {

  const scroll = (id) => {
    document.getElementById(id).scrollIntoView({behavior: 'smooth'})
  }

  return (
    <div className="vh-100 page-1 p-0 m-0 d-flex flex-column gap-5">
    <div className="d-flex justify-content-between px-5 py-3">
      <img src="/img/logo.png" alt="Dawdlers Logo" className="p-2" />
      {<ConnectButton metamask={metamask} provider={provider} setIsOpen={setIsOpen} isOpen={isOpen} />}
    </div>
  </div>
  )
}
