import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import Desktop from './Desktop';
import Mobile from './Mobile';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { ethers } from "ethers";
import Admin from "./Admin"
import contractABI from "./utils/Dawdlerz_metadata.json"

function App() {

  const [provider, setProvider] = useState({
    provider: null,
    address: null,
    contract: null,
    contractDetail: null
  });

  const metamask = async() => {
    const _provider = new ethers.providers.Web3Provider(window.ethereum)
    const accounts = await _provider.send("eth_requestAccounts", []);
    const _contract = await new ethers.Contract(process.env.REACT_APP_CONTRACT_ADDRESS, contractABI.output.abi, _provider.getSigner())
    await setProvider({ provider: {..._provider, address: accounts[0]}, address: accounts[0], contract: _contract })
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={      
              !isMobile 
              ? <Desktop metamask={metamask} provider={provider} /> 
              : <Mobile metamask={metamask} provider={provider} />
        } />

        <Route path="/admin" element={<Admin metamask={metamask} provider={provider} />} />
        <Route path="*" element={      
              !isMobile 
              ? <Desktop metamask={metamask} /> 
              : <Mobile metamask={metamask} provider={provider} />
        } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
