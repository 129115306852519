import React, { useState, useEffect } from 'react'
import { ethers } from "ethers";
import { Buffer } from "buffer/";

window.Buffer = window.Buffer || Buffer;

const { MerkleTree } = require("merkletreejs")
const keccak256 = require("keccak256")

const key_value = (obj) => {
  return Object.entries(obj).map(([k,v]) => `${k}: ${v}`);
}

function Admin({metamask, provider}) {
  const [localContract, setLocalContract] = useState(null);

  useEffect(() => {getContractDetail()}, [provider.contract])

  const getContractDetail = async () => {
    if(provider.contract){
      let name = await provider.contract.name()
      let isMintAllow = await provider.contract.isMintAllow(1)
      const obj = {
        name: name,
        isMintAllow: isMintAllow,
        isWhitelistMintAllow: await provider.contract.isWhitelistMintAllow(1),
        isTeamMintAllow: await provider.contract.isTeamMintAllow(1),
      }
      setLocalContract({...obj })
    }
  }

  const setWhitelist = async () => {
    const value = await (await fetch('/whitelist.json')).json();
    const leaves = value.map(addr => keccak256(addr))
    const merkleTree = new MerkleTree(leaves, keccak256, {sortPairs: true})
    const rootHash = merkleTree.getRoot()
    console.log(rootHash)
    await provider.contract.setMerkleRoot(rootHash)
  }

  const setAirdrop = async () => {
    const value = await (await fetch('/airdrop.json')).json();
    const leaves = value.map(addr => keccak256(addr))
    const merkleTree = new MerkleTree(leaves, keccak256, {sortPairs: true})
    const rootHash = merkleTree.getRoot()
    console.log(rootHash)
    await provider.contract.setAirdropMerkleRoot(rootHash)
  }

  const merkleProof = async () => {
    const value = await (await fetch('/whitelist.json')).json();
    const leaves = value.map(addr => keccak256(addr))
    const merkleTree = new MerkleTree(leaves, keccak256, {sortPairs: true})
    let address = document.getElementById('whitelistProof').value // The input
    let hashedAddress = keccak256(address)
    let proof = merkleTree.getHexProof(hashedAddress)
    console.log(proof)
  }

  const mintPausable = async() => {
    await provider.contract.mintPausable()
  }

  const withdraw = async() => {
    const signer = provider.provider.getSigner()
    const contractWithSigner = provider.contract.connect(signer);
    await contractWithSigner.withdraw()
  }

  return (
    <div className='bg-dark text-white d-flex flex-column' style={{minWidth:"100vw", minHeight:"100vh"}}>
      {
        provider.address
        ?
        <span className="btn btn-success" >
          Connected
        </span>
        :
        <button className="btn btn-warning" onClick={metamask}>Connect</button>
      }
      {
        localContract ?
        <>
          <div className='d-flex flex-column text-white justify-content-center align-items-center pt-3 fs-4'>
            <div>Infos:</div>
            {key_value(localContract).map((item, key) => {
              return (
                <div key={`row-${key}`}>
                  {item}
                </div>
              )
            })}
          </div>
          <hr />
          <div className="container">
            <div className="row px-3">
              <div className="col-md-3 col-sm-12">
                  <button className="btn btn-danger" onClick={() => setWhitelist()}>Set Whitelist</button>
                  <button className="btn btn-danger" onClick={() => setAirdrop()}>Set Airdrop Whitelist</button>
                  <input id="whitelistProof" />
                  <button onClick={() => merkleProof()}>whitelist proof</button>
              </div>
              <div className="col-md-3 col-sm-12 d-flex flex-column">
                  <button className="btn btn-primary my-3" onClick={() => mintPausable()}>
                    mint change status {`${localContract?.isMintAllow}`}
                  </button>
                  <button className="btn btn-primary my-3" onClick={() => withdraw()}>Withdraw</button>
              </div>
            </div>

          </div>
        </>
        : <></>
      }
    </div>
  )
}

export default Admin;
