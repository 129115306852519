import React from 'react'

const longText = "The Dawdlerz is a collection of 8,888 NFTs, each with different and fun traits. Some Dawdlers are more unique than others. Dawdlerz is much more than an avatar. A huge community, a crazy universe."

export default function Five() {
  return (
    <div className="d-flex flex-column p-0 m-0" style={{zIndex:99}}>
      <img src="/img/nftgallery.png" alt="NFT Gallery" className="img-fluid" />
      <div className="d-flex flex-column justify-content-center align-items-center py-4 gap-3">
        <div className="fs-1">
          <div className="lh-1">More than </div>
          <div className="lh-1">an avatar,  </div>
          <div className="lh-1">it's a universe. </div>
        </div>
          <div className="text-square blue-text fs-5 px-5 d-flex justify-content-center">
            {longText}
          </div>
      </div>
    </div>
  )
}
