import React from 'react'
const team_members = [
  {
    name: 'KARO',
    image: '/img/team/KARO.png',
    title: 'Founder',
  },
  {
    name: 'CHAKAYA',
    image: '/img/team/CHAKAYA.png',
    title: 'Marketing',
  },
  {
    name: 'LinusChain',
    image: '/img/team/LinusChain.png',
    title: 'Developer',
  },
  {
    name: 'SUN',
    image: '/img/team/SUN.png',
    title: 'Artist',
  },
]
export default function Four() {
  return (
  <div className="vh-100 d-flex flex-column gap-4 m-0 p-0 page-4 position-relative overflow-hidden" id="team">
      <img src="/img/bottomthree.png" alt="" className="bottomImages align-self-start" />
      <div className="fs-1 align-self-center text-white pt-3">TEAM</div>
      <div className="d-flex justify-content-center align-items-center gap-2 text-white">
        {
          team_members.map((member, index) => {
            return (
              <div className="d-flex flex-column justify-content-center align-items-center display-front-2" key={`team-member-${index}`}>
                <img src={member.image} alt={member.title} className="team-member img-fluid" />
                <div className="fw-bold fs-2" style={{letterSpacing: '2px'}}>{member.name}</div>
                <div className="fs-5 fst-normal">{member.title}</div>
              </div>
            )
          })
        }
      </div>
        <div></div>
  </div>
  )
}
