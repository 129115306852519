import React from 'react'

export default function Two() {
  return (
    <div className="vh-100 page-2 p-5 m-0 d-flex flex-column align-items-end text-danger fs-4 eight-bit">
      <div>Location: Dawdler City</div>
      <div>
        <span>Status: In Operation<span id="blinkSquare" className="align-top">&#9646;</span></span>
      </div>
    </div>
  )
}
