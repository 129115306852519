import React from 'react'

const FAQlist = [
  {
    question: 'What\'s the collection size?',
    answer: '8888'
  },
  {
    question: 'What\'s the mint price?',
    answer: '0,05 Ethereum'
  },
  {
    question: 'How much will creator fees be on secondary marketplaces?',
    answer: 'We will receive 5% of all secondary sales. These proceeds will be used to continue to hire more full-time employees, fund operations and deliver even more value to our community.'
  },
  {
    question: 'How can I be part of the Dawdlerz community?',
    answer: 'Join Discord and follow us on Twitter.'
  },
]

export default function Six() {
  return (
    <div className="page-6 d-flex flex-column align-items-center position-relative" id="faq">
      <div className="fs-1 pt-5 pb-5">FAQ</div>
      <div className="d-flex flex-column gap-2" style={{maxWidth: '50%'}}>
        {
          FAQlist.map((item, index) => {
            return(
              <div key={`faq-${index}`}>
                <button className="collapsible px-4 fs-4">{item.question}</button>
                <div className="content px-5 blue-text">
                  <p>
                    {item.answer}
                  </p>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}
