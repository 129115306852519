import React from 'react'

const social = [
  {
    name: 'Discord',
    img: '/img/discord.png',
    link: process.env.REACT_APP_DISCORD,
  },
  {
    name: 'OpenSea',
    img: '/img/opensea.png',
    link: process.env.REACT_APP_OPENSEA,
  },
  {
    name: 'Twitter',
    img: '/img/twitter.png',
    link: process.env.REACT_APP_TWITTER,
  },
  {
    name: 'Instagram',
    img: '/img/instagram.png',
    link: process.env.REACT_APP_INSTAGRAM,
  },
  {
    name: 'Medium',
    img: '/img/medium.png',
    link: process.env.REACT_APP_MEDIUM,
  }
]

const social2 = [social[0], social[2], social[3]]

export default function Seven() {
  return (
    <div className="page-7 d-flex flex-column justify-content-start align-items-end pe-5 position-relative" id="contact">
      <div className="fs-1">CONTACT US</div>
      <div className="d-flex flex-column gap-2 w-25">
        {
          social2.map((item, index) => {
            return(
              <div key={`social-${index}`} className="link-social-button">
                <a href={item.link}>
                  <div className="link-social pe-5">{item.name}</div>
                </a>
              </div>
            )
          })
        }
      </div>
      <img src="/img/copyright.png" alt="Dawdlerz Footer Logo" className="position-absolute start-0 bottom-0 p-3 ps-5" style={{marginLeft:"170px"}} />
      <div className="position-absolute end-0 bottom-0 p-3" style={{marginRight: "100px"}}>
        {
          social.map((item, index) => {
            return(
              <a href={`${item.link}`} key={`social-${index}`}>
                <img src={item.img} alt={`Dawdlerz ${item.name}`} className="pe-3" />
              </a>
            )
          })
        }
      </div>
    </div>
  )
}
