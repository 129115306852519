import React, { useState } from 'react'
import MintModal from './MintModal'
import {
  One,
  Two,
  Three,
  Four,
  Five,
  Six,
  Seven,
} from './pages/desktop'

export default function Desktop({metamask, provider}) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <MintModal provider={provider} isOpen={isOpen} setIsOpen={setIsOpen} />
      <One metamask={metamask} provider={provider} isOpen={isOpen} setIsOpen={setIsOpen}  />
      <Two />
      <Three />
      <Four />
      <Five />
      <Six />
      <Seven />
    </>
  )
};
