import React, { useEffect } from 'react'
import { useState } from 'react';
import Modal from 'react-awesome-modal';
import {ethers} from 'ethers';
const {MerkleTree} = require("merkletreejs")
const keccak256 = require("keccak256")

const TOTAL_MINT = 188;

const sleep = ms => new Promise(r => setTimeout(r, ms));

// const AirdropContent = ({ provider, setContent }) => {
//   const [available, setAvailable] = useState("loading");
//   const [error, setError] = useState();
//   const [merkleProof, setMerkleProof] = useState("");
//   useEffect(() => {
//     const airdropJson = async() => {
//       const _value = await (await fetch('/airdrop.json')).json();
//       let leaves = _value.map(addr => keccak256(addr))
//       let merkleTree = new MerkleTree(leaves, keccak256, {sortPairs: true})
//       let hashedAddress = keccak256(provider.address)
//       let proof = merkleTree.getHexProof(hashedAddress)
//       const res = await provider.contract.AirDropVerify(proof, provider.address)
//       console.log(res);
//       setAvailable(res);
//       setMerkleProof(proof);
//     }
//     airdropJson();
//   }, [provider])

//   const airdrop = async() => {
//     try {
//       setAvailable("loading");
//       await provider.contract.airdropMint(merkleProof);
//       setAvailable("ok");
//     }
//     catch(e) {
//       console.log(e);
//       setAvailable("error");
//       setError(e);
//     }
//   }

//   return(
//     <div className='d-flex flex-column gap-5 p-5'>
//       <h1 className='text-center'>Airdrop Mint</h1>
//       {
//         available === "loading" && (
//           <div className="spinner-grow align-self-center text-info" style={{width:"3rem", height:"3rem"}} role="status">
//             <span className="visually-hidden">Loading...</span>
//           </div>
//         )
//       }
//       {
//         (available === true && available !== "ok" )&& (
//           <button className='btn btn-success fs-5'
//           onClick={async() => {
//             await airdrop();
//           }}
//           >🥳 Mint Available 🥳</button>
//         )
//       }
//       {
//         available === false && (
//           <button className='btn btn-outline-danger fs-5'>❌ Not Available ❌</button>
//         )
//       }
//       {
//         available === "error" && (
//           <div className="alert alert-danger" role="alert">
//             {error.reason}
//           </div>
//         )
//       }
//       {
//         (available === "ok" )&& ( 
//           <button className='btn btn-info fs-5'>🥳 Minted 🥳</button>
//         )
//       }
//       <button className='btn' onClick={() => setContent('main')}>Back</button>
//     </div>
//   )
// }

// const FreeMintContent = ({ provider, setContent }) => {
//   const [available, setAvailable] = useState("loading");
//   const [error, setError] = useState("")
//   useEffect(() => {
//     const check = async() => {
//       const available = await provider.contract.totalMinted();
//       setAvailable(available);
//     }
//     check();
//   }, [provider])
//   return(
//     <div className='d-flex flex-column gap-5 p-5'>
//       <h1 className='text-center'>Free Mint</h1>
//       {
//         available === "loading" && (
//           <div className="spinner-grow align-self-center text-info" style={{width:"3rem", height:"3rem"}} role="status">
//             <span className="visually-hidden">Loading...</span>
//           </div>
//         )
//       }
//       {
//         (available < TOTAL_MINT && available !== "ok" )&& (
//           <button className='btn btn-success fs-5'
//           onClick={async() => {
//             try {
//               setAvailable("loading");
//               await provider.contract.freeMint();
//               setAvailable("ok");
//             }
//             catch(e) {
//               console.log(e);
//               setAvailable("error");
//               setError(e);
//             }
//           }}
//           >🥳 Mint Available 🥳</button>
//         )
//       }
//       {
//         available === "error" && (
//           <div className="alert alert-danger" role="alert">
//             {error.reason}
//           </div>
//         )
//       }
//       {
//         (available === "ok" )&& ( 
//           <button className='btn btn-info fs-5'>🥳 Minted 🥳</button>
//         )
//       }
//       <button className='btn' onClick={() => setContent('main')}>Back</button>
//     </div>
//   )
// }

// const WhitelistMint = ({ provider, setContent }) => {
//   const [available, setAvailable] = useState("loading");
//   const [mintPrice, setMintPrice] = useState();
//   const [merkleProof, setMerkleProof] = useState();
//   const [error, setError] = useState();

//   useEffect(() => {
//     const whitelistJson = async() => {
//       setMintPrice(await provider.contract.MINT_PRICE());
//       console.log("price: ", await provider.contract.MINT_PRICE())
//       const _value = await (await fetch('/whitelist.json')).json();
//       let leaves = _value.map(addr => keccak256(addr))
//       let merkleTree = new MerkleTree(leaves, keccak256, {sortPairs: true})
//       let hashedAddress = keccak256(provider.address)
//       let proof = merkleTree.getHexProof(hashedAddress)
//       const res = await provider.contract.verifyProof(proof, provider.address)
//       setAvailable(res);
//       setMerkleProof(proof);
//     }
//     whitelistJson();
//   }, [provider])

//   const mint = async() => {
//     try {
//       setAvailable("loading");
//       const options = {value: mintPrice}
//       await provider.contract.whitelistMint(provider.address, merkleProof, options);
//       setAvailable("ok");
//     }
//     catch(e) {
//       console.log(e);
//       setError(e);
//       setAvailable("error");
//     }
//   }

//   return(
//     <div className='d-flex flex-column gap-5 p-5'>
//       <h1 className='text-center'>Whitelist Mint</h1>
//       {
//         available === "loading" && (
//           <div className="spinner-grow align-self-center text-info" style={{width:"3rem", height:"3rem"}} role="status">
//             <span className="visually-hidden">Loading...</span>
//           </div>
//         )
//       }
//       {
//         available === true && (
//           <>
//             <div className='text-center fs-5'>{ethers.utils.formatEther(mintPrice)} ETH</div>
//             <button className='btn btn-success fs-5' onClick={() => mint()}>🥳 Mint Available 🥳</button>
//           </>
//         )
//       }
//       {
//         available === false && (
//           <button className='btn btn-outline-danger fs-5'>❌ Not Available ❌</button>
//         )
//       }
//       {
//         available === "error" && (
//           <div className="alert alert-danger" role="alert">
//             {error.reason}
//           </div>
//         )
//       }
//       {
//         (available === "ok" ) && (
//           <button className='btn btn-info fs-5'>🥳 Minted 🥳</button>
//         )
//       }
//       <button className='btn' onClick={() => setContent('main')}>Back</button>
//     </div>
//   )
// }

const NormalMint = ({ provider, setContent }) => {
  const [quantity, setQuantity] = useState(1);
  const [mintPrice, setMintPrice] = useState();
  const [error, setError] = useState();
  const [available, setAvailable] = useState("loading");
  useEffect(() => {
    const getPrice = async() => {
      try{
        setError("")
        setMintPrice(await provider.contract.MINT_PRICE());
        setAvailable("ok");
      }
      catch(e) {
        setError(e)
        console.log(e);
      }
    }
    getPrice();
  }, [provider])
  const mint = async() => {
    try {
      setError("")
      setAvailable("loading");
      const options = {value: mintPrice.mul(quantity)}
      await provider.contract.mint(quantity, options);
      setAvailable("ok");
    }
    catch(e) {
      setError(e)
      console.log(e);
    }
  }
  return(
    <div className='d-flex flex-column gap-5 p-5'>
      <h1 className='text-center'>Mint</h1>
        {
          available === "loading" && (
            <div className="spinner-grow align-self-center text-info" style={{width:"3rem", height:"3rem"}} role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          )
        }
        {
          !!error && (
            <div className="alert alert-danger" role="alert">
              {error?.reason || JSON.stringify(error)}
            </div>
          )
        }
        {
          available === "ok" && (
            <>
              {
                <div>
                  <div className='text-center fs-5'>{ethers.utils.formatEther(mintPrice)} ETH / Dawdlerz</div>
                </div>
              }
              <div class="input-group mb-3">
                <input type="number" class="form-control" placeholder="Quantity" aria-label="Quantity" aria-describedby="Quantity-addon" 
                value={quantity}
                onChange={(e) => setQuantity(Number(e.target.value))}
                />
                <button
                className="btn"
                onClick={() => quantity > 1 && setQuantity(quantity - 1)}
                >
                  -
                </button>
                <button
                className="btn"
                onClick={() => setQuantity(quantity + 1)}
                >
                  +
                </button>
              </div>
              <button className='btn btn-success fs-5' onClick={() => mint()}>🥳 Mint 🥳</button>
            </>
          )
        }
      <button className='btn' onClick={() => setContent('main')}>Back</button>
    </div>
  )
}


export default function MintModal({ provider, isOpen, setIsOpen }) {
  const [content, setContent] = useState("mint");
  const [totalMinted, setTotalMinted] = useState(0);

  const _setContent = async(content) => {
    setIsOpen(false)
    setContent(content);
    await sleep(100);
    setIsOpen(true)
  }

  useEffect(() => {
    const getTotalMinted = async() => {
      if(provider){
        setTotalMinted((await provider.contract.totalMinted()).toNumber() );
      }
    }
    getTotalMinted();
  }, [provider])

  return (
    <Modal visible={isOpen} effect="fadeInUp" onClickAway={() => setIsOpen(false)} >
      {
        content === "main" && (
          <div className="p-5 d-flex flex-column gap-5">
              <h1>Mint Options</h1>
              {/* <button className="btn btn-info text-white"
                onClick={async() => {
                  await _setContent('airdrop')
                }}
              >Check Airdrop</button>
              <button className="btn btn-success"
                onClick={async() => {
                  await _setContent('freeMint')
                }}
              >Free Mint Available {totalMinted}/188</button>
              <button className='btn btn-warning text-white'
                onClick={async() => {
                  await _setContent('whitelistMint')
                }}
              >Check Whitelist</button> */}
              <button className="btn btn-primary"
                onClick={async() => {
                  await _setContent('mint');
                }}
              >Mint</button>
              <button className="btn w-100 btn-outline-info" onClick={() => setIsOpen(false)}>Close</button>
          </div>
        )
      }
      {/* {
        content === "freeMint" && (
          <FreeMintContent provider={provider} setContent={setContent} />
        )
      }
      {
        content === "whitelistMint" && (
          <WhitelistMint provider={provider} setContent={setContent} />
        )
      } */}
      {
        content === "mint" && (
          <NormalMint provider={provider} setContent={setContent} />
        )
      }
      {/* {
        content === "airdrop" && (
          <AirdropContent provider={provider} setContent={setContent} />
        )
      } */}
  </Modal>
  )
}
