import React from 'react'

const longText = "The Dawdlerz is a collection of 8,888 NFTs, each with different and fun traits. Some Dawdlers are more unique than others. Dawdlerz is much more than an avatar. A huge community, a crazy universe."

export default function Five() {
  return (
    <div className="d-flex justify-content-center gap-5 align-items-center vh-100 p-0 m-0 display-front-3" style={{zIndex:99}}>
      <img src="/img/nftgallery.png" alt="NFT Gallery" className="img-fluid" />
      <div className="d-flex flex-column justify-content-center align-items-start pe-5" style={{width:"22%"}}>
        <div className="fs-1">
          <div className="lh-1">More than </div>
          <div className="lh-1">an avatar,  </div>
          <div className="lh-1">it's a universe. </div>
        </div>
          <div className="textsquare blue-text fs-5">
            {longText}
          </div>
      </div>
    </div>
  )
}
