import React from 'react'

const social = [
  {
    name: 'Discord',
    img: '/img/discord.png',
    link: 'https://discord.gg/rNSE7SN7',
  },
  {
    name: 'OpenSea',
    img: '/img/opensea.png',
    link: 'https://opensea.io/',
  },
  {
    name: 'Twitter',
    img: '/img/twitter.png',
    link: 'https://twitter.com/DawdlerzNFT',
  },
  {
    name: 'Instagram',
    img: '/img/instagram.png',
    link: 'https://www.instagram.com/dawdlerzofficial/',
  },
  {
    name: 'Medium',
    img: '/img/medium.png',
    link: 'https://medium.com/@dawdlerz',
  }
]


export default function Seven() {
  return (
    <div className="d-flex flex-column align-items-center gap-3">
      <img src="/img/cp.png" alt="Copyright" className="img-fluid bg-dark w-100"/>
      <div className="d-flex justify-content-center gap-2" id="contact">
          {
            social.map((item, index) => {
              return(
                <a href={`${item.link}`} key={`social-${index}`}>
                  <img src={item.img} alt={`Dawdlerz ${item.name}`} className="img-fluid" />
                </a>
              )
            })
          }
      </div>
    </div>
  )
}
